var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vx-card',{attrs:{"title":"Simple Stock Transfer Order Import"}},[_c('div',{staticClass:"vx-row mb-6",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full flex items-center"},[_c('vs-button',{staticClass:"mt-2",attrs:{"color":"danger","icon-pack":"feather","icon":"icon-arrow-left"},on:{"click":function($event){return _vm.handleBack()}}},[_vm._v("Back")])],1),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('vs-button',{staticClass:"mt-2",attrs:{"color":"primary","type":"border","icon-pack":"feather","icon":"icon-download"},on:{"click":function($event){return _vm.handleDownloadTemplate()}}},[_vm._v("Template Download")])],1)]),_c('div',{staticClass:"vx-row mb-6",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full flex items-center"},[_c('span',[_vm._v("Supplier")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('Supplier',{attrs:{"value":this.selectedSupplier},on:{"selected":(val) => {
                    this.selectedSupplier = val
                }}})],1)]),_c('div',{staticClass:"vx-row mb-6",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full flex items-center"},[_c('span',[_vm._v("Source Territory")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('Territory',{attrs:{"value":this.selectedSrcTerritory},on:{"selected":(val) => {
                this.selectedSrcTerritory = val
            }}})],1)]),_c('div',{staticClass:"vx-row mb-6",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full flex items-center"},[_c('span',[_vm._v("Dest. Territory")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('Territory',{attrs:{"value":this.selectedDestTerritory},on:{"selected":(val) => {
                this.selectedDestTerritory = val
            }}})],1)]),_c('div',{staticClass:"vx-row mb-6",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full flex items-center"},[_c('span',[_vm._v("Posting Date")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('Date',{attrs:{"value":this.postingDate,"add-days":1},on:{"selected":(val) => {
                this.postingDate = val
            }}})],1)]),_c('div',{staticClass:"vx-row mb-6",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full flex items-center"},[_c('span',[_vm._v("Notes")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('vs-textarea',{attrs:{"width":"100%"},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}})],1)]),_c('div',{staticClass:"vx-row mb-6",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full flex items-center"},[_c('span',[_vm._v("File (xls, xlsx)")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('input',{ref:"fileInput",attrs:{"type":"file","accept":".xlsx, .xls"},on:{"change":_vm.handleChangeFile}})])]),_c('div',{staticClass:"vx-row mb-6",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full flex items-center"}),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('vs-button',{staticClass:"mt-2",attrs:{"color":"primary","type":"border","icon-pack":"feather","icon":"icon-upload"},on:{"click":function($event){return _vm.handleImport()}}},[_vm._v("Import")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }