<template>
    <vx-card title="Simple Stock Transfer Order Import">
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <vs-button class="mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                    @click="handleBack()">Back</vs-button>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-button class="mt-2" color="primary" type="border" icon-pack="feather" icon="icon-download"
                    @click="handleDownloadTemplate()">Template Download</vs-button>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Supplier</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <Supplier 
                    :value="this.selectedSupplier" 
                    @selected="(val) => {
                        this.selectedSupplier = val
                    }"
                ></Supplier>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Source Territory</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <Territory :value="this.selectedSrcTerritory" @selected="(val) => {
                    this.selectedSrcTerritory = val
                }"></Territory>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Dest. Territory</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <Territory :value="this.selectedDestTerritory" @selected="(val) => {
                    this.selectedDestTerritory = val
                }"></Territory>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Posting Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <Date :value="this.postingDate" @selected="(val) => {
                    this.postingDate = val
                }" :add-days="1"></Date>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Notes</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-textarea v-model="notes" width="100%" />
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>File (xls, xlsx)</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <input type="file" ref="fileInput" accept=".xlsx, .xls" @change="handleChangeFile" />
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-button class="mt-2" color="primary" type="border" icon-pack="feather" icon="icon-upload"
                    @click="handleImport()">Import</vs-button>
            </div>
        </div>
    </vx-card>
</template>

<script>
import moment from "moment";
import Territory from "./component/Territory.vue";
import Supplier from "./component/Supplier.vue";
import Date from "./component/Date.vue";
export default {
    components: {
        Territory,
        Supplier,
        Date,
    },
    data() {
        return {
            selectedSrcTerritory: null,
            selectedSupplier: null,
            selectedDestTerritory: null,
            postingDate: null,
            notes: null,
            month: null,
            file: null,
        }
    },
    mounted() {
        var now = moment()
        now.add(1, 'days')
    },
    methods: {
        handleDownloadTemplate() {
            this.$vs.loading();
            var fileTitle = "SIMPLE_STO_TEMPLATE";
            this.$http
                .get(this.$store.state.outbound.simpleSto.baseUrlPath + "/import/template", {
                    params: {
                        month: this.month ? moment(this.month).format("YYYY-MM-DD") : null,
                    },
                    responseType: "arraybuffer",
                    headers: {
                        Accept: "application/octet-stream",
                    },
                })
                .then((resp) => {
                    this.$vs.loading.close();
                    if (resp.status == "error") {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    } else {
                        var fileURL = window.URL.createObjectURL(new Blob([resp]));
                        var fileLink = document.createElement("a");
                        fileLink.href = fileURL;

                        fileLink.setAttribute(
                            "download",
                            fileTitle.toUpperCase() + ".xlsx"
                        );
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    }
                });
        },
        handleBack() {
            this.$router.push({
                name: this.$store.state.outbound.simpleSto.baseRouterName,
            });
        },
        handleImport() {
            if (!this.file) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "File is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            let formData = new FormData();
            formData.append("file", this.file);

            this.$vs.loading();
            this.$http
                .post(this.$store.state.outbound.simpleSto.baseUrlPath + "/import/create", formData)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });

                        this.$vs.loading.close();
                        this.draw++;
                        this.setDefault();
                    } else {
                        this.$vs.loading.close();
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    }
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        handleChangeFile(event) {
            this.file = event.target.files ? event.target.files[0] : null;
            if (this.file) {
                const reader = new FileReader();
                reader.readAsBinaryString(this.file);
            }
        },
    }
}
</script>